<template>
  <div class="controllerBuye">
    <div class="controller-modules-header left">静脉输液</div>
    <div class="selectForm">
      <div class="item left">
        <h4>IV#1 流体</h4>
        <a-select v-model:value="form.selectValueV1" placeholder="请选择" @change="handelSelectChange(true)">
          <template #suffixIcon>
            <img
              class="select_img"
              src="@/assets/images/ico_select.png"
              alt=""
            />
          </template>
          <a-select-option
            :value="item.type"
            v-for="(item, index) in liutiList"
            :key="index"
            >{{ item.name }}</a-select-option
          >
        </a-select>
      </div>
      <div class="item left" v-show="form.selectValueV1">
        <h4>输注</h4>
        <a-select v-model:value="form.selectValueV1Shu" placeholder="请选择"  >
          <template #suffixIcon>
            <img
              class="select_img"
              src="@/assets/images/ico_select.png"
              alt=""
            />
          </template>
          <a-select-option
            :value="item"
            v-for="(item, index) in jingmaishuzhuList"
            :key="index"
            >{{ item }}</a-select-option
          >
        </a-select>
        mL/hr
      </div>
      <div class="item left" v-show="form.selectValueV1">
        <h4>推注</h4>
        <a-select v-model:value="form.selectValueV1Tui" placeholder="请选择">
          <template #suffixIcon>
            <img
              class="select_img"
              src="@/assets/images/ico_select.png"
              alt=""
            />
          </template>
          <a-select-option
            :value="item"
            v-for="(item, index) in jingmaituizhuList"
            :key="index"
            >{{ item }}</a-select-option
          >
        </a-select>
        mL
      </div>
    </div>
    <div class="selectForm" v-show="form.selectValueV1">
      <div class="item left">
        <h4>IV#2 流体</h4>
        <a-select v-model:value="form.selectValueV2" placeholder="请选择" @change="handelSelectChange(false)">
          <template #suffixIcon>
            <img
              class="select_img"
              src="@/assets/images/ico_select.png"
              alt=""
            />
          </template>
          <a-select-option
            :value="item.type"
            v-for="(item, index) in liutiList"
            :key="index"
            >{{ item.name }}</a-select-option
          >
        </a-select>
      </div>
      <div class="item left"  v-show="form.selectValueV2">
        <h4>输注</h4>
        <a-select v-model:value="form.selectValueV2Shu" placeholder="请选择">
          <template #suffixIcon>
            <img
              class="select_img"
              src="@/assets/images/ico_select.png"
              alt=""
            />
          </template>
          <a-select-option
            :value="item"
            v-for="(item, index) in jingmaishuzhuList"
            :key="index"
            >{{ item }}</a-select-option
          >
        </a-select>
        mL/hr
      </div>
      <div class="item left"  v-show="form.selectValueV2">
        <h4>推注</h4>
        <a-select v-model:value="form.selectValueV2Tui" placeholder="请选择" >
          <template #suffixIcon>
            <img
              class="select_img"
              src="@/assets/images/ico_select.png"
              alt=""
            />
          </template>
          <a-select-option
            :value="item"
            v-for="(item, index) in jingmaituizhuList"
            :key="index"
            >{{ item }}</a-select-option
          >
        </a-select>
        mL
      </div>
    </div>
    <button class="controller-btn" :class="submitController?'active':''" @click="submit()">确认</button>
    <div class="controller-modules-log">
     <block v-show="actionLog.length != 0">
        <div class="item" v-for="(item, index) in actionLog" :key="index">
        【{{formateSeconds(item.logTime)}}】{{item.logContent}}
        </div>
      </block>
      <block v-show="actionLog.length == 0">
        <div class="item" >
            暂无操作记录。
        </div>
      </block>
    </div>
  </div>
</template>
<script>
import { liutiList, jingmaituizhuList, jingmaishuzhuList } from "@/utils/data";
import { mapGetters, mapActions } from "vuex";
import  {formateSeconds} from '@/utils/tool'
export default {
  data() {
    return {
      liutiList,
      jingmaituizhuList,
      jingmaishuzhuList,
      form: {
        selectValueV1: undefined,
        selectValueV1Shu: undefined,
        selectValueV1Tui: undefined,
        selectValueV2: undefined,
        selectValueV1Shu: undefined,
        selectValueV1Tui: undefined,
      },
      formateSeconds,
      actionLog:[],
      selectOnceChange:false,
    };
  },
  created(){
    this.getActionLog();
    // this.form = Object.assign(this.form,this.buyeSelectForm);
  },
  methods: {
    ...mapActions({
      submitStep: "exma/submitStep",
      setBuye: "controller/setBuye",
    }),
    initData(){
      this.form.selectValueV1 = undefined;
      this.form.selectValueV1Shu = undefined;
      this.form.selectValueV1Tui = undefined;
      this.form.selectValueV2 = undefined;
      this.form.selectValueV2Shu = undefined;
      this.form.selectValueV2Tui = undefined;
    },
    handelSelectChange(type){
      console.log('type',type)
      if(type){
        this.form.selectValueV1Shu = undefined;
        this.form.selectValueV1Tui = undefined;
      }else{
        this.form.selectValueV2Shu = undefined;
        this.form.selectValueV2Tui = undefined;
      }
      // if(!this.selectOnceChange)this.selectOnceChange = true;
    },
    async submit(type) {
      if(!this.submitController)return;
      let form = this.form || {};
      if (!form.selectValueV1) return;
      let fluidsData = [{
        "bolus":form.selectValueV1Tui || "",
        "infusion":form.selectValueV1Shu || "",
        "fluids":form.selectValueV1 || "",
      }];
      if(form.selectValueV2){
        fluidsData.push({
          "bolus":form.selectValueV2Tui || "",
          "infusion":form.selectValueV2Shu || "",
          "fluids":form.selectValueV2 || "",
        })
      }
      let d = {
        memberCaseId: this.memberCaseId,
        component: "flulds",
        actionTime: this.getHandleTimeAt,
        otherData: {
          fluids:fluidsData
        },
      };
      this.submitStep(d).then(res=>{
        // this.setBuye({...form})
        this.initData();
        this.getActionLog();
      });
    },
     async getActionLog(){
      try{
        let res = await this.$http.post(this.$interface.examActionLog,{
          componentCategory:'flulds',
          memberCaseId:this.memberCaseId,
        })
        this.actionLog = res.data.data || [];
      }catch(err){}
    }
  },
  computed: {
    ...mapGetters(["getHandleTimeAt", "memberCaseId","buyeSelectForm"]),
    submitController() {
      // if(!this.selectOnceChange)return false;
      if(!this.form.selectValueV1 || !this.form.selectValueV1Shu || !this.form.selectValueV1Tui)return false;
      if(this.form.selectValueV2){
        if(!this.form.selectValueV2Shu || !this.form.selectValueV2Tui)return false;
      }
      return true;
    },
  },
};
</script>
<style lang="scss">
@import "../index.scss";
</style>